.archive {
    background: #eee;
}

.archive__title,
.archive__link-title {
    font-family: $font-title;
    text-transform: uppercase;
    margin: 0;
    line-height: 1;
}

.archive__title {
    font-size: 2.25rem;
    margin-bottom: 1rem;
    transform: translateX(-0.1em);

    @media screen and(min-width: $tablet) {
        font-size: 3rem;
    }

    @media screen and(min-width: $desktop) {
        font-size: 5rem;
        max-width: 960px;
        margin: 0 auto 0;
    }
}

.archive__link-title {
    font-size: 1.5rem;

    @media screen and(min-width: $desktop) {
        font-size: 3rem;
    }
}

.archive__list {
    margin: 0;
    @media screen and(min-width: $desktop) {
        padding-top: 1rem;
    }
}

.archive__item {
    max-width: 960px;
    margin: 0 auto 1rem;

    @media screen and(min-width: $desktop) {
        margin-bottom: 2rem;

        &:nth-child(odd) {
            .archive__content-container:first-child {
                padding-right: 0.5rem;
            }

            .archive__content-container:last-child {
                padding-left: 0.5rem;
            }
        }

        &:nth-child(even) {
            .archive__link {
                flex-direction: row-reverse;
            }

            .archive__content-container:first-child {
                padding-left: 0.5rem;
            }

            .archive__content-container:last-child {
                padding-right: 0.5rem;
            }
        }
    }
}

.archive__link {
    position: relative;
    display: block;
    box-shadow: 0px 1px 5px rgba(0,0,0,0.25);

    &:hover,
    &:focus {
        .archive__link-bg {
            opacity: 1;
        }
    }

    @media screen and(min-width: $desktop) {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
}

.archive__content-container {
    padding: 1rem;
    @media screen and(min-width: $desktop) {
        flex: 0 0 auto;
        width: 50%;
    }
}

.archive__link-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.75;
    transition: all 150ms;
}

.archive__link-img-container {
    position: relative;
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    margin-bottom: -1rem;

    @media screen and(min-width: $desktop) {
        margin-bottom: 0;
    }
}

.archive__link-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    object-fit: cover;
}