*,
*:before,
*:after {
    box-sizing: border-box;
}

html,
body {
    padding: 0;
    margin: 0;
    min-height: 100vh;
    overflow-x: hidden;
}

header,
nav,
main,
footer {
    display: block;
}

img {
    max-width: 100%;
    height: auto;
}

ul,
ol {
    padding: 0;
    list-style-type: none;
    margin: 0;
}

.bg-srcset {
    display: none;
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.bg-container {
    background: transparent no-repeat center;
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    transform: scale(1.00);
    filter: blur(50px);
    z-index: -999;

    &.loaded {
        transform: scale(1);
        filter: blur(0);
    }
}

main {
    position: relative;
    padding: 1rem;
}

.media-container {
    position: relative;
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

a {
    display: inline;
    text-decoration: none;
    color: inherit;
}

.wrapper-inner {
    margin: 0 auto 0 0;
    width: 100%;
    max-width: 40rem;
    padding: 1rem;
}

svg {
    display:inline-block;
    vertical-align: middle;
    fill: currentColor;
}
