@font-face {
    font-family: 'opensans';
    src: url('#{$assets-folder}fonts/OpenSans-Regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'opensans';
    src: url('#{$assets-folder}fonts/OpenSans-Light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'opensans';
    src: url('#{$assets-folder}fonts/OpenSans-Bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'montserrat';
    src: url('#{$assets-folder}fonts/montserrat-bold-webfont.woff2') format('woff2'),
         url('#{$assets-folder}fonts/montserrat-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'montserrat';
    src: url('#{$assets-folder}fonts/montserrat-regular-webfont.woff2') format('woff2'),
         url('#{$assets-folder}fonts/montserrat-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}