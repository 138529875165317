/*
 * Theme Name: Imke 2018
 * Description: keimooi.
 * Author: Tom
*/
$assets-folder: '/wp-content/themes/imke-2018/assets/';
@import 'utils';
@import 'components/fonts';
@import 'components/typography';
@import 'components/basics';
@import 'components/header';
@import 'components/main-nav';
@import 'components/performance';
@import 'components/lightbox';
@import 'components/archive';
@import 'components/home';
@import 'components/statement';
@import 'components/links';
@import 'components/404';
@import 'components/about';
@import 'components/pagination';