.page__info {
    position: relative;
    margin: -1rem;
    padding: 1em;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: no-repeat center;
        background-size: cover;
        opacity: 0.75;
        z-index: -1;
    }

    a {
        border-bottom: 1px dotted currentColor;

        &:hover,
        &:focus {
            border-bottom-style: solid;
        }
    }

    & > *:last-child {
        margin-bottom: 0;
    }

    @media screen and (min-width: $desktop) {
        padding: 1.5rem;
        margin: 0 0 1.5rem;
    }
}

.performance__event,
.performance__duration,
.performance__photographers {
    position: relative;
    margin-bottom: 1em;
    padding-left: 1.75em;
}

.performance__event {
    padding-left: 0;
    span:not(:last-child):after {
        content: ', ';
    }
}

.performance__video-button {
    position: relative;
    border: none;
    background: none;
    font-size: 1rem;
    padding: 0.25em 0.4em 0.25em 2.25em;
    margin-left: -0.5em;
    cursor: pointer;
    color: inherit;
    transition: background 150ms ease-in, color 150ms ease-out;

    .performance__icon {
        transform: translate(0.5em, -19%);
    }
}

.performance__icon {
    width: 1.25em;
    height: auto;
    position: absolute;
    top: 0.75em;
    left: 0;
    transform: translateY(-47%);
}

.performance__gallery {
    margin: 0 -1rem;
    padding-top: 2rem;
    min-height: calc(100vh - 1rem);

    @media screen and (min-width: $desktop) {
        margin: 0;
        padding-top: 0;
        min-height: 0; 
    }
}

.performance__gallery-item {
    position: relative;
    float: left;
    padding: 0.5rem;
    margin: 0 1rem 1rem 0;       

    @media screen and (min-width: $desktop) {
        margin: 0 1.5rem 1.5rem 0;
    }
}

.performance__gallery-button {
    position: relative;
    display: block;
    width: 75px;
    height: 75px;
    outline: 0.5rem solid rgba(0, 0, 0, 0.75);
    border: none;
    background: transparent no-repeat center;
    background-size: cover;
    z-index: 1;
    cursor: pointer;    
    padding: 0;

    @media screen and (min-width: $tablet) {
        width: 100px;
        height: 100px;
    }
}