.lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.85);
    z-index: 1000;
    overflow: hidden;
    transition: all 150ms;

    &.closed {
        opacity: 0;
        pointer-events: none;
    }
}

.lightbox__items {
    list-style-type: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 1000vw;
    height: 100%;
    margin: 0;
    padding: 0;
}

.lightbox__items:not(.no-transition) {
    transition: all 150ms ease-in-out;
}

.lightbox__video-container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 75%;
    max-width: 1920px;
    transform: translate(-50%, -50%);
}

.lightbox__iframe-container {
    position: relative;
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}    

.lightbox__item {
    position: relative;
    width: 100vw;
    height: 100%;
    padding: 5vw;
    padding: 5vmin;
    align-items: center;
    justify-content: center;
    float: left;

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        border: 0.25rem solid;
        animation: spinner 1000ms linear infinite;
        opacity: 0.75; 
        transform: translate(-50%, -50%) rotate(0.001deg) scale(1);
    }
}

@keyframes spinner {
    0% {
        border-color: white;
        border-right-color: transparent;
        transform: translate(-50%, -50%) rotate(0.001deg) scale(1);
    }

    50% {
        border-color: white;
        border-left-color: transparent;
        transform: translate(-50%, -50%) rotate(180deg) scale(0.9);
    }

    100% {
        border-color: white;
        border-right-color: transparent;
        transform: translate(-50%, -50%) rotate(359.999deg) scale(1);
    }
}

.lightbox__image {
    position: absolute;
    width: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 90vw;
    max-height: 75vh;
    max-height: 75vmin;
}

.lightbox__nav {
    position: fixed;
    top: 50%;
    width: 5vmin;
    height: 5vmin;
    min-width: 40px;
    min-height: 40px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0.75;
    transition: all 150ms;

    &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-3px, -50%);
        width: 0;
        height: 0;
        border-style: solid; 
        border-width: 5px 0 5px 8.66px;
        border-color: transparent transparent transparent currentColor;
    }

    &:hover,
    &:focus,
    &:active {
        opacity: 1;
        outline: none;
    }
}

.lightbox__nav--prev {
    left: 5vmin;
    transform: translate(-50%, -50%);

    &:after {
        transform: rotate(180deg) translate(6px, 50%) ;        
    }
}

.lightbox__nav--next {
    right: 5vmin;
    transform: translate(50%, -50%);
}

.lightbox__nav--close {
    top: 5vmin;
    right: 5vmin;
    transform: translate(50%, -50%);
}