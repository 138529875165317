.links {
    background: #d2d2d2 url("#{$assets-folder}img/links.jpg") no-repeat right center fixed;
    background-size: cover;

    .page__info::before {
		background: white;
    }
    
    .page__info a {
        display: inline-block;
        padding: .25em .5em;
        margin: .5em 0;
        border-bottom: 1px dotted #000;
        background: hsla(0,0%,100%,.75);

        &:focus,
        &:hover {
            color: #fff;
            background: #000;
        }
    }
}