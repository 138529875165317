$color-base: rgb(255, 128, 128);

.home {
    background: #d2d2d2 url("#{$assets-folder}img/imke-zeinstra.jpg") no-repeat center bottom fixed;
    background-size: contain;
}

.home__title {
    display: inline;
    line-height: 1;
    font-size: 13vw;
    color: rgba($color-base, 0.75);
    span {
        font-family: inherit;
        line-height: inherit;
        color: rgba($color-base, 0.85);
    }
}

.home__nav {
    display: inline-block;
    vertical-align: bottom;
    height: 13vw;
    padding-top: 1rem;
}

.home__link {
    display: block;
    font-size: 2rem;
    padding: 0.25rem;
    text-decoration: underline;

    &:focus,
    &:hover {
        color: rgba($color-base, 0.75);
    }
}