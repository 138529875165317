.main-nav {
    margin: 0;
    position: relative;

    @media screen and (min-width: $desktop) {
        display: inline-block;
        vertical-align: bottom;
    }
}

.main-nav__toggle {
    position: absolute;
    display: block;
    right: 0;
    bottom: 100%;
    margin: 0 0.25rem 0.25rem 0;
    background: rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    padding: 0;
    z-index: 10;
    cursor: pointer;
    transition: all 150ms;
    
    &:before,
    &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        height: 3px;
        background: white;
        border-radius: 2px;
        width: 1.5rem;
        z-index: 1;
        transform: translate(-50%, -50%) rotate(-180deg);
        transition: all 200ms;
    }

    &:before {
        box-shadow: 0 -7px white, 0 7px white;
    }

    &:after {
        transform: translate(-50%, -50%) rotate(180deg);
    }

    &:hover,
    &:focus {
        outline: none;
        background: rgba(0, 0, 0, 0.5);

        &:before {
            box-shadow: 0 -9px white, 0 9px white;
        }
    }

    .menu-open & {
        &:before {
            box-shadow: 0 0 white, 0 0 white;
            transform: translate(-50%, -50%) rotate(-315deg);
        }

        &:after {
            transform: translate(-50%, -50%) rotate(135deg);
        }
    }

    @media screen and (min-width: $desktop) {
        display: none;
    }
}

.main-nav__list {
    margin: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 150ms;

    .menu-open & {
        max-height: calc(100vh - 60px);
        overflow: auto;
    }
    
    @media screen and (min-width: $desktop) { 
        max-height: none !important;
        overflow: visible;
    }
}

.main-nav__item {
    position: relative;

    @media screen and (min-width: $desktop) {
        .main-nav__list > & {
            float: left;
        }

        &:focus,
        &:hover {
            .main-nav__list--sub-nav {
                display: block;
            }
        }
    }
}

.main-nav__link {
    position: relative;
    display: block;
    text-decoration: none;
    color: white;
    padding: 0.5em 1em;
    transition: all 150ms;

    .main-nav__list--sub-nav & {
        padding-left: 2em;
    }

    &:focus,
    &:hover {
        background: rgba(0, 0, 0, 0.1);
    }

    @media screen and (min-width: $desktop) {
        padding: 1rem;
        line-height: 1;
    
        .main-nav__item--has-children > & {
            padding-right: 2rem;
            &:after {
                content: '';
                position: absolute;
                top: 50%;
                right: 1rem;
                width: 0;
                height: 0;
                border: 5px solid transparent;
                border-top-color: currentColor;
                transform: translate(50%, -2px);
            }
        }

        .main-nav__list--sub-nav & {
            padding-left: 1em;
        }
    }
}

.main-nav__list--sub-nav {
    @media screen and (min-width: $desktop) {
        position: absolute;
        top: 100%;
        left: 0;
        width: 280px;
        display: none;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: currentColor;
            opacity: 0.95;
            z-index: -1;
        }
    }
}