$font-base: opensans, sans-serif;
$font-title: montserrat, $font-base;

* {
    font-family: $font-base;
    line-height: 1.5;
}

h1 {
    font-family: $font-title;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 1;
    margin: -0.09em 0 0 -0.09em;

    @media screen and (min-width: $tablet) {
        font-size: 3.5rem;
    }
    
    @media screen and (min-width: $desktop) {
        font-size: 4.5rem;
    }
}