.pagination {
    max-width: 960px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.pagination__link {
    text-transform: uppercase;
    position: relative;
    display: block;
    text-align: center;
    padding: 1rem;
    line-height: 1;
    width: 50%;
    width: calc(50% - 0.5rem);
    flex: 0 0 auto;
    font-weight: bold;
    color: black;
    overflow: hidden;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 200%;
        height: 100%;
        background: red;
        transition: all 150ms;
        z-index: -1;
    }

    &.pagination__link--prev:before {
        background: hotpink linear-gradient(35deg, hotpink, orange) no-repeat;
    }

    &.pagination__link--next:before {
        background: yellowgreen linear-gradient(35deg, royalblue, yellowgreen) no-repeat;
    }

    &[rel]:hover:before,
    &[rel]:focus:before {
        transform: translateX(-50%);
    }

    &[aria-disabled="true"] {
        pointer-events: none;
        touch-action: none;
        opacity: 0.5;
    }
}