.header {
    position: relative;
    z-index: 1000;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: currentColor;
        opacity: 0.75;
        z-index: -1;
        transition: all 150ms;
    }

    @media screen and (min-width: $desktop) {
        &:hover:before {
            opacity: 0.95;
        }
    }
}

.logo {
    display: inline-block;
    font-family: $font-title;
    text-transform: uppercase;
    font-weight: bold;
    color: white;
    line-height: 2;
    text-decoration: none;
    font-size: 1.5rem;
    padding: 0 1rem;

    @media screen and (min-width: $desktop) {
        padding-left: 2rem;
    }
}